import React, { useState, useEffect, useRef } from 'react';
import { Link } from "gatsby"

const Header = (props) => {
  const [navOpen, setNavOpen] = useState('');
  // const [hover, setHover] = useState(false);

  const handleClick = () => {
    if(navOpen == 'open-nav'){
      setNavOpen('')
    }
    else{
      setNavOpen('open-nav')
    }
  }
  const animationContainerRef = useRef(null);
 
  useEffect(() => {
    
    
    const mobileView = window.matchMedia("(max-width: 991px)");
    if(mobileView.matches){

    }else{
    //navigation transition 
    const triggerSection = document.querySelector('.showreel');
    const header = document.querySelector('.site-header');
    let prevScrollPos = window.pageYOffset;
    
    window.onscroll = function() {
      const currentScrollPos = window.pageYOffset;
      if(prevScrollPos < currentScrollPos){
        header.style.backgroundColor = "white";
        header.style.padding = "16px 0px";
        if(header.classList.contains("white-header")){
          header.classList.add("white-header-fixed");
        }
      }
      if (currentScrollPos <= 80) {
        // At the top of the page, make the header transparent
        header.style.backgroundColor = "transparent";
        header.style.padding = "2rem 0rem 0rem 0rem";
        header.classList.remove("white-header-fixed");
      }
      
      if(triggerSection){
        if (currentScrollPos >= triggerSection.offsetTop) {
          if (currentScrollPos > prevScrollPos) {
              // Scrolling down, hide the header
              header.classList.add('hide-header');
              header.classList.add('show-header');
              
          } else {
              // Scrolling up, show the header
              header.classList.remove('hide-header');
              header.classList.add('show-header');
              header.style.backgroundColor = "white";
              header.style.padding = "16px 0px";
          }
        } else {
          // The user hasn't reached the trigger section, always show the header
          header.classList.remove('hide-header');
          header.classList.remove('show-header');
        }
      }
    
      prevScrollPos = currentScrollPos;
    };
      
    }
    
    // localStorage.setItem("activeLink", activeLink);
    setTimeout(() => {
      // let navigationBlock = gsap.timeline({defaults: {ease: "Power4.easeInOut"}});
      // navigationBlock.to(".navigation-block .site-navigation ul", 1.5, {
      //   y: 0,
      //   ease: "Power4.easeInOut",
      //   stagger: {
      //     amount: 0.3
      //   }
      // });

      // let btnBlock = gsap.timeline({defaults: {ease: "Power4.easeInOut"}});
      // btnBlock.to(".branding", 1.5, {
      //   y: 0,
      //   // marginTop: 0,
      //   ease: "Power4.easeInOut",
      //   stagger: {
      //     amount: 0.3
      //   }
      // });

      // const animation = lottie.loadAnimation({
      //   container: animationContainerRef.current,
      //   renderer: 'svg',
      //   loop: false,
      //   // autoplay: true,
      //   animationData: Logo
      // });
      // animationContainerRef.current.addEventListener('mouseenter', () => {
      //   animation.play();
      // });
      // animation.addEventListener('complete', () => {
      //   animation.stop();
      // });


    },3500)
  },[])

  

  // const handleNav = (link) => {
  //   setActiveLink(link)
  // }

  return (
    <>
    <header className={"site-header " + navOpen + " " + props.extraClass}>
      <div className="container">
        <div className="branding">
          <Link to="/" 
          // id="logo-lottie" 
          // ref={animationContainerRef}
          >
          {/* <Lottie
            animationData={Logo}
            height={20}
            width={159}
            loop={false}
          /> */}
            <svg xmlns="http://www.w3.org/2000/svg" width="159" height="20" viewBox="0 0 159 20" fill="none">
            <path className="fill-color" d="M0 19.6458V0.210938H7.06721C8.622 0.210938 10.0354 0.493626 11.2369 0.988331C12.4383 1.48304 13.4277 2.18976 14.2051 3.03782C14.9825 3.88589 15.6185 4.94597 16.0426 6.1474C16.4666 7.34882 16.6786 8.55025 16.6786 9.89302C16.6786 11.3771 16.4666 12.7199 16.0426 13.9213C15.6185 15.1228 14.9825 16.1122 14.1344 16.9602C13.2864 17.8083 12.297 18.4443 11.0955 18.9391C9.8941 19.4338 8.622 19.6458 7.13789 19.6458H0V19.6458ZM12.8623 9.89302C12.8623 8.97428 12.721 8.12622 12.509 7.34882C12.297 6.57143 11.8022 5.86471 11.3075 5.29933C10.8128 4.73395 10.1768 4.30992 9.47007 3.95656C8.76334 3.6032 7.98595 3.53253 7.06721 3.53253H3.74562V16.3242H7.06721C7.98595 16.3242 8.83402 16.1828 9.54074 15.8295C10.2475 15.4761 10.8835 15.0521 11.3782 14.4867C11.8729 13.9213 12.2263 13.2146 12.509 12.4372C12.721 11.6598 12.8623 10.8118 12.8623 9.89302Z" fill="#1A1733"/>
            <path className="fill-color" d="M35.8304 16.3242V19.6458H22.332V0.210938H35.5477V3.53253H26.0777V8.19689H34.2756V11.2358H26.0777V16.3242H35.8304Z" fill="#1A1733"/>
            <path className="fill-color" d="M39.9297 9.75337C39.9297 8.55195 40.1417 7.42119 40.5657 6.21977C40.9898 5.08901 41.6258 4.02893 42.4739 3.11019C43.3219 2.19145 44.3114 1.48473 45.5128 0.919355C46.6435 0.353978 47.9863 0.0712891 49.5411 0.0712891C51.3079 0.0712891 52.8627 0.495322 54.2055 1.27272C55.4776 2.05011 56.467 3.11019 57.103 4.31162L54.2055 6.29044C53.9228 5.72506 53.6401 5.30103 53.2867 4.877C52.9334 4.52363 52.5093 4.24095 52.0853 3.95826C51.6613 3.74624 51.2372 3.6049 50.7425 3.46355C50.3185 3.39288 49.8238 3.32221 49.3998 3.32221C48.481 3.32221 47.633 3.53422 46.9262 3.88759C46.2195 4.24095 45.6541 4.73565 45.1594 5.3717C44.6647 6.00775 44.3114 6.6438 44.0993 7.42119C43.8873 8.19859 43.746 8.97598 43.746 9.75337C43.746 10.6014 43.8873 11.4495 44.17 12.2269C44.4527 13.0043 44.8061 13.711 45.3008 14.3471C45.7955 14.9831 46.4315 15.4071 47.1382 15.8312C47.845 16.1845 48.6224 16.3966 49.4704 16.3966C49.8945 16.3966 50.3892 16.3259 50.8132 16.2552C51.3079 16.1139 51.7319 15.9725 52.156 15.7605C52.58 15.5485 53.004 15.1951 53.3574 14.8418C53.7108 14.4884 53.9934 13.9937 54.2055 13.499L57.315 15.2658C57.0323 15.9725 56.5376 16.6086 55.9723 17.1739C55.4069 17.7393 54.7708 18.234 54.0641 18.5874C53.3574 18.9408 52.58 19.2941 51.7319 19.4355C50.8839 19.6475 50.1065 19.7181 49.3291 19.7181C47.9156 19.7181 46.6435 19.4355 45.5128 18.8701C44.382 18.3047 43.3926 17.5273 42.5446 16.6086C41.6965 15.6898 41.0604 14.6298 40.6364 13.4283C40.1417 12.2269 39.9297 11.0255 39.9297 9.75337Z" fill="#1A1733"/>
            <path className="fill-color" d="M70.7435 19.7882C69.3301 19.7882 68.058 19.5055 66.8565 18.9401C65.7258 18.3748 64.7364 17.668 63.8883 16.7493C63.0402 15.8306 62.4042 14.7705 61.9802 13.569C61.5561 12.3676 61.2734 11.1662 61.2734 9.8941C61.2734 8.622 61.4855 7.3499 61.9802 6.14847C62.4749 4.94705 63.1109 3.88697 63.959 2.96823C64.807 2.04949 65.7965 1.34277 66.9979 0.777393C68.1993 0.212016 69.4007 0 70.8142 0C72.2276 0 73.4997 0.282688 74.6305 0.848065C75.7612 1.41344 76.7506 2.19084 77.5987 3.10957C78.4468 4.02831 79.0828 5.08839 79.5069 6.28982C79.9309 7.49125 80.2136 8.69267 80.2136 9.8941C80.2136 11.1662 80.0016 12.4383 79.5069 13.569C79.0121 14.7705 78.3761 15.7599 77.528 16.6786C76.68 17.5974 75.6906 18.3041 74.4891 18.8695C73.3584 19.5055 72.0863 19.7882 70.7435 19.7882ZM65.0897 9.8941C65.0897 10.7422 65.2311 11.5196 65.4431 12.2969C65.7258 13.0743 66.0791 13.7811 66.5738 14.3464C67.0686 14.9825 67.6339 15.4065 68.3407 15.8306C69.0474 16.1839 69.8248 16.3959 70.7435 16.3959C71.6622 16.3959 72.4396 16.1839 73.1464 15.8306C73.8531 15.4772 74.4185 14.9825 74.9132 14.3464C75.4079 13.7104 75.7612 13.0037 75.9732 12.2263C76.1853 11.4489 76.3266 10.6715 76.3266 9.82342C76.3266 8.97536 76.1853 8.19797 75.9026 7.42057C75.6199 6.64318 75.2665 5.93646 74.7718 5.37108C74.2771 4.73503 73.7117 4.311 73.005 3.95764C72.2983 3.60428 71.5209 3.39226 70.6022 3.39226C69.6834 3.39226 68.906 3.60428 68.1993 3.95764C67.4926 4.311 66.9272 4.8057 66.4325 5.44175C65.9378 6.0778 65.5844 6.71385 65.3724 7.49124C65.2311 8.33931 65.0897 9.1167 65.0897 9.8941Z" fill="#1A1733"/>
            <path className="fill-color" d="M83.8871 15.6888C84.1698 15.9008 84.5938 16.0421 85.1592 16.3248C85.7953 16.5368 86.4313 16.6782 87.2087 16.6782C87.9861 16.6782 88.5515 16.5368 89.0462 16.2541C89.4702 15.9714 89.8236 15.6181 90.1063 15.0527C90.3183 14.4873 90.5303 13.8513 90.601 13.0032C90.6717 12.2258 90.7423 11.2364 90.7423 10.1763V0.282227H94.4879V10.1763C94.4879 11.6604 94.4173 13.0032 94.2759 14.2046C94.1346 15.4061 93.7812 16.4661 93.2865 17.3142C92.7918 18.1623 92.0851 18.869 91.0957 19.293C90.1769 19.7877 88.8342 19.9998 87.2794 19.9998C85.6539 19.9998 84.2405 19.5757 83.0391 18.7983L83.8871 15.6888Z" fill="#1A1733"/>
            <path className="fill-color" d="M114.561 16.3242V19.6458H101.062V0.210938H114.278V3.53253H104.808V8.19689H113.006V11.2358H104.808V16.3242H114.561Z" fill="#1A1733"/>
            <path className="fill-color" d="M124.027 7.20748V19.6458H120.281V0.210938H123.179L133.285 12.9319V0.210938H137.031V19.6458H133.992L124.027 7.20748Z" fill="#1A1733"/>
            <path className="fill-color" d="M158.45 3.53253H152.23V19.6458H148.485V3.53253H142.266V0.210938H158.45V3.53253Z" fill="#1A1733"/>
            </svg>
          </Link>
        </div>
        <div className="menu-icon" onClick={handleClick} >
          <div></div>
          <div></div>   
          <div></div>
        </div>
        <div className="navigation-block">
          <div className="site-navigation">
            <ul>
              <li>
                <Link to="/work" className=''>
                  <span data-hover="W">W</span>
                  <span data-hover="o">o</span>
                  <span data-hover="r">r</span>
                  <span data-hover="k">k</span>
                </Link>
              </li>
              <li>
                <Link to="/process" className='link-hover'>
                  <span data-hover="P">P</span>
                  <span data-hover="r">r</span>
                  <span data-hover="o">o</span>
                  <span data-hover="c">c</span>
                  <span data-hover="e">e</span>
                  <span data-hover="s">s</span>
                  <span data-hover="s">s</span>
                </Link>
              </li>
              <li>
                <Link to="/about" className='link-hover'>
                  <span data-hover="A">A</span>
                  <span data-hover="b">b</span>
                  <span data-hover="o">o</span>
                  <span data-hover="u">u</span>
                  <span data-hover="t">t</span>
                </Link>
              </li>
              <li>
                <Link to="/blog" className='link-hover'>
                  <span data-hover="B">B</span>
                  <span data-hover="l">l</span>
                  <span data-hover="o">o</span>
                  <span data-hover="g">g</span>
                </Link>
              </li>
            </ul>
          </div>
          <div className="btn-block"><Link to="/contact" className="btn primary-btn" >Start a Project</Link></div>
        </div>
        {/* <div className='btnDestination'></div> */}
        <div className="btn-block interaction">
          <Link to="/contact" className="btn outline-btn" >
            <span className="btn-text">Start a Project</span>
            <i className="btn__icon btn__icon--append icon-arrow-right-up"></i>
          </Link>
        </div>
      </div>
    </header>
    </>
  )
}

export default Header