import * as React from "react"
import { Link } from "gatsby"

import Logo from "./../assets/images/logo-icon.svg"
import FooterIllustration from "./../assets/images/footerillustartion.svg"
import NewLogo from "./../assets/images/newlogo.svg";
import ReactPlayer from 'react-player';
import Lottie from "lottie-react";

import FooterLineArt from "../assets/Letsdosomething.mp4"

//social icons
import Linkedin from "./../assets/images/linkedin.svg";
import Dribble from "./../assets/images/dribbble.svg";
import Facebook from "./../assets/images/facebook.svg";
import Instagram from "./../assets/images/instagram.svg";
import Behance from "./../assets/images/behance.svg";
import footerAnimation from "../assets/footerAnimation.json";

const footer = () => {
  return (
    <footer className="site-footer">
        <div className="container">
            <div className="footer-title">
                <div>
                    <h2 className="h1 section-title">let's do something<br/> great together!</h2>
                    <div className="btn-block">
                        <Link to="/" className="btn primary-btn">
                            <span className="btn-text">Connect Now</span>
                            <i className="btn__icon btn__icon--append icon-arrow-right-up"></i>
                        </Link>
                    </div>
                </div>
                <div className="image-block">
                    <Lottie animationData={footerAnimation}/>
                    {/* <img
                        src={FooterIllustration}
                        width="100%"
                        alt="Footer illustration"
                    /> */}
                    {/* <ReactPlayer
                       className='react-player'
                       id="video"
                       url={FooterLineArt}
                       playing={true}
                       loop={true} 
                       muted={true}
                       playsinline={true}
                    //    light={true}
                    /> */}
                </div>
            </div>
            <div className="nav-block">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="contact-block">
                            <div className="logo">
                                <img
                                    src={NewLogo}
                                    width={80}
                                    alt="Decojent"
                                />
                            </div>
                            <p className="hello-text">say hello!</p>
                            <p className="small"><a href="mailto:hello@decojent.com">hello@decojent.com</a></p>
                        </div>
                    </div>
                    <div className="col-lg-4"></div>
                    <div className="col-lg-15">
                        <div className="navigation-block">
                            <div className="group">
                                <ul>
                                    <li><p className="small link-heading">Links</p></li>
                                    <li><p className="extra-small"><Link to="/work">Work</Link></p></li>
                                    <li><p className="extra-small"><Link to="/">Process</Link></p></li>
                                    <li><p className="extra-small"><Link to="/about">About</Link></p></li>
                                    <li><p className="extra-small"><Link to="/">Blog</Link></p></li>
                                    <li><p className="extra-small"><Link to="/contact">FAQs</Link></p></li>
                                </ul>
                            </div>
                            <div className="group">
                                <ul>
                                    <li><p className="small link-heading">Case Study</p></li>
                                    <li><p className="extra-small popular"><Link to="/work/limeflight">LimeFlight</Link></p></li>
                                    <li><p className="extra-small"><Link to="/work/tribetic">Tribetic</Link></p></li>
                                    <li><p className="extra-small comingsoon"><Link to="/work/innago">Innago</Link></p></li>
                                    <li><p className="extra-small"><Link to="/work/xaqt">Xaqt</Link></p></li>
                                </ul>
                            </div>
                            <div className="group">
                                <ul>
                                    <li><p className="small link-heading">Office</p></li>
                                    <li><p className="extra-small">Office# 302 - 303, Rim Jhim Mall Block 6 Gulshan-e-Iqbal, Karachi</p></li>
                                    <li><p className="extra-small">5214F Diamond Hts Blvd, Unit #1254, San Francisco, CA 94131</p></li>
                                    <li><p className="extra-small"><a className="telephone" href="tel:(415) 965-8811">(415) 965-8811</a></p></li>
                                </ul>
                            </div>
                        </div>
                        {/* <div className="navigation-block mbl-nav">
                            <div className="group">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/">Work</Link></li>
                                    <li><Link to="/">About</Link></li>
                                    <li><Link to="/">About Us</Link></li>
                                    <li><Link to="/">Blog</Link></li>
                                    <li><Link to="/">Career</Link></li>
                                    <li><Link to="/">Contact</Link></li>
                                    <li><Link to="/">Dribble</Link></li>
                                    <li><Link to="/">Instagram</Link></li>
                                    <li><Link to="/">Facebook</Link></li>
                                    <li><Link to="/">LinkedIn</Link></li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="copyright-text">
                    <p className="extra-small">© Decojent {new Date().getFullYear()}. All rights reserved.</p>
                </div>
                <div className="social-icons">
                    <ul>
                        <li>
                            <Link to="https://pk.linkedin.com/company/decojent">
                                <img 
                                    src={Linkedin}
                                    width={24}
                                />
                            </Link>
                        </li>
                        <li>
                            <Link to="https://dribbble.com/decojent">
                                <img 
                                    src={Dribble}
                                    width={24}
                                />
                            </Link>
                        </li>
                        <li>
                            <Link to="https://www.behance.net/decojent-ui-ux">
                                <img 
                                    src={Behance}
                                    width={24}
                                />
                            </Link>
                        </li>
                        <li>
                            <Link to="https://www.instagram.com/decojent/?hl=en">
                                <img 
                                    src={Instagram}
                                    width={24}
                                />
                            </Link>
                        </li>
                        <li>
                            <Link to="https://www.facebook.com/decojent/">
                                <img 
                                    src={Facebook}
                                    width={12}
                                />
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
  )
}

export default footer